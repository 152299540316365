import * as React from 'react';
import {Icon} from "office-ui-fabric-react";

interface IFileAddButtonProps {
    onAddFiles:(event)=>void;
}

class FileAddButton extends React.Component<IFileAddButtonProps>{

    constructor(props: Readonly<IFileAddButtonProps>) {
        super(props);
    }

    render(){
        return(
            <div className="ms-Grid-col note-image-grid">
                <div className="note-image-container add">
                    <button className="file-input" tabIndex={-1}>
                        <input
                            type="file"
                            multiple
                            onChange={(event)=>{this.props.onAddFiles(event)}}
                        />
                        {/*
                        accept=".jpg, .jpeg, .png"
                        */}
                        Legg til filer
                    </button>
                </div>
            </div>
        )
    }
}

export default FileAddButton;