import {API_CODE, BASE_API_URL} from "./Actions";
import axios from "axios";
import {Reklamasjon} from "../models/Reklamasjon";
import {AttachmentFile} from "../models/AttachmentFile";

export const GET_REKLAMASJONER:string = 'GET_REKLAMASJONER';
export const CREATE_REKLAMASJON:string = 'CREATE_REKLAMASJON';
export const UPLOAD_ATTACHMENT:string = 'UPLOAD_ATTACHMENT';
export const RESET_REKLAMASJON_STATE:string = 'RESET_REKLAMASJON_STATE';

export function getReklamasjoner() {

    return new Promise((resolve) => {
        const request = axios.get(`${BASE_API_URL}/reklamasjoner${API_CODE}`, {
            headers:{
                Authorization: localStorage.getItem('token')
            }
        }).then(response=>{

            resolve({
                type: GET_REKLAMASJONER,
                payload: response.data
            });
        }).catch(error=>{

            resolve({
                type: GET_REKLAMASJONER,
                payload: null
            });
        });
    });
}

export function createReklamasjon(reklamasjon:Reklamasjon, callback:(ReklamasjonID:string)=>void) {


    return new Promise((resolve)=>{
       const request = axios.post(`${BASE_API_URL}/reklamasjoner${API_CODE}`, reklamasjon, {
           headers:{
               Authorization: localStorage.getItem('token')
           }
       }).then(response=>{

           resolve({
               type: CREATE_REKLAMASJON,
               payload: response
           });

           callback(response.data.fields.id);

       }).catch(error=>{
           resolve({
               type: CREATE_REKLAMASJON,
               payload: null
           });
       })
    });
}

export function uploadReklamasjonAttachment(file:AttachmentFile, ReklamasjonID:string) {

    const formData = new FormData();
    formData.append('file', file.file);

    return new Promise((resolve)=>{
        const request = axios.post(`${BASE_API_URL}/reklamasjoner/attachments${API_CODE}&id=${ReklamasjonID}`, formData,{
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(response=>{

            resolve({
                type: UPLOAD_ATTACHMENT,
                payload: response
            });

        }).catch(error=>{

            resolve({
                type: UPLOAD_ATTACHMENT,
                payload: null
            });

        });
    });
}

export function resetReklamasjonState() {
    return{
        type: RESET_REKLAMASJON_STATE,
        payload: null
    }
}