import * as React from 'react';

import './Index.scss';
import LoginForm from "../login/LoginForm";

interface IIndexProps{
    history:any;
}

interface IIndexState{

}

class Index extends React.Component<IIndexProps, IIndexState>{

    constructor(props: Readonly<IIndexProps>) {
        super(props);
    }

    renderMenuButton(){

        if (localStorage.getItem('token')){
            return(
                <div className="index-button-container">
                    <button onClick={()=>this.props.history.push('/min-side')}>
                        <span>Min side</span>
                    </button>
                </div>
            )
        }

        return(
            <div className="index-button-container">
                <button onClick={()=>this.props.history.push('/login')}>
                    <span>Logg inn</span>
                </button>
            </div>
        )
    }

    render(){
        return(
            <div>
                <div className="index-image-container">
                    <div className="index-logo-container">
                        <img src="/src/assets/img/logo_white.png"></img>
                    </div>
                    <div className="index-slogan-container">
                        <h3>Liten nok til å lytte, stor nok til å levere</h3>
                    </div>
                    {this.renderMenuButton()}
                </div>
                <div className="index-banner-container vertical-align">
                    <div>
                        <div className="banner-text vertical-align">
                            <div className="title">
                                Boligportal
                            </div>
                            <div className="company">
                                Ruta Entreprenør AS
                            </div>
                        </div>
                    </div>
                </div>

                {/*

                <div className="ms-Grid">
                    <div className="ms-Grid-col ms-sm12 ms-smOffset0 ms-md12 ms-mdOffset1 ms-lg8 ms-lgOffset2 ms-xl6 ms-xlOffset3 ms-xxl4 ms-xxlOffset4">
                        <LoginForm/>
                    </div>
                </div>
              */}
            </div>
        )
    }
}

export default Index;