import * as React from 'react';

import './NoPageViewContent.scss';
import {Icon} from "office-ui-fabric-react";

export interface INoPageViewContentProps {
    title?:string;
    descripton?:string;
    iconName?:string;
}

class NoPageViewContent extends React.Component<INoPageViewContentProps>{

    constructor(props){
        super(props);
    }


    render(){
        return(
            <div className="ms-Grid-col ms-sm12 ms-lg8 ms-textAlignCenter no-page-view-content p-y-20">
                {this.props.iconName &&
                    <div className="p-y-10">
                        <Icon iconName={this.props.iconName}/>
                    </div>
                }
                <h3 className="m-y-0">{this.props.title}</h3>
                <p>{this.props.descripton}</p>
            </div>
        )
    }
}

export default NoPageViewContent;