import * as React from 'react';
import {tokenExpired} from "../../utils/AuthUtils";

import {
    Route,
    Redirect,
} from 'react-router-dom'


const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        !tokenExpired()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
    )} />
);

export default ProtectedRoute;