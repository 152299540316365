import axios from 'axios';
import {API_CODE, BASE_API_URL} from "./Actions";

export const AUTH_LOGIN_SUCCESS:string = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE:string = 'AUTH_LOGIN_FAILURE';
export const AUTH_CREATE_SUCCESS:string = 'AUTH_CREATE_SUCCESS';
export const AUTH_CREATE_FAILURE:string = 'AUTH_CREATE_FAILURE';

export function login(username:string, password:string, callback:(token:string)=>void) {

    if (!username || !password){
        return{
            type: AUTH_LOGIN_FAILURE,
            payload: 'Vennligst skriv inn bruker og passord'
        }
    }

    const requestData = {
        username: username,
        password: password
    };

    return new Promise((resolve, reject) => {
        const request = axios.post(`${BASE_API_URL}/auth/login${API_CODE}`, requestData).then(response=>{

            callback(response.data.jwt);

            resolve({
                type: AUTH_LOGIN_SUCCESS,
                payload: response.data
            });
        }).catch(error=>{

            callback(null);

            resolve({
                type: AUTH_LOGIN_FAILURE,
                payload: 'Kunne ikke logge deg inn. Sjekk at brukernavn og passord er skrevet riktig'
            });
        });
    });
}

export function changePassword(password:string, newPassword:string, callback:(success:boolean)=>void) {

    const requestData = {
        password,
        newPassword
    };

    return new Promise((resolve, reject) => {
        const request = axios.post(`${BASE_API_URL}/auth/create${API_CODE}`, requestData, {
            headers:{
                Authorization: localStorage.getItem('token')
            }
        }).then(response=>{

            callback(true);

            resolve({
                type: AUTH_CREATE_SUCCESS,
                payload: response.data
            });
        }).catch(error=>{

            callback(false);

            resolve({
                type: AUTH_CREATE_FAILURE,
                payload: 'Kunne ikke oppdatere passord. Vennligst sjekk at du skrev inn ditt nåværende passord riktig.'
            });
        });
    });
}