import * as React from 'react';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PageViewContainer from "../common/PageViewContainer";
import {getReklamasjoner} from "../../actions/Action_Reklamasjon";
import {Reklamasjon} from "../../models/Reklamasjon";
import ReklamasjonListItem from "./ReklamasjonListItem";
import PageViewHeader from "../common/PageViewHeader";
import SpinLoader from "../loading/SpinLoader";
import {Icon} from "office-ui-fabric-react";
import NoPageViewContent from "../common/NoPageViewContent";
import {Prosjekt} from "../../models/Prosjekt";
import {getProsjekt} from "../../actions/Action_Prosjekt";


interface IReklamasjonListViewProps {
    reklamasjoner:Reklamasjon[];
    prosjekt:Prosjekt;

    getReklamasjoner();
    getProsjekt()

    history:any;
}

interface IReklamasjonListViewState {
    isAwaitingReklamasjonerResponse:boolean;
}

class ReklamasjonListView extends React.Component<IReklamasjonListViewProps, IReklamasjonListViewState>{

    constructor(props:Readonly<IReklamasjonListViewProps>) {
        super(props);

        this.state = {
            isAwaitingReklamasjonerResponse: this.props.reklamasjoner ? true : false
        };
    }

    componentWillMount(){
        this.props.getReklamasjoner().then(response=>{
            this.setState({isAwaitingReklamasjonerResponse: false});
        });

        if (!this.props.prosjekt){
            this.props.getProsjekt();
        }
    }

    renderReklamasjoner(){
        if (this.state.isAwaitingReklamasjonerResponse && !this.props.reklamasjoner.length){
            return(
                <div className="p-y-20 ms-Grid-col ms-sm12 ms-lg8">
                    <SpinLoader text="Laster inn reklamasjoner"/>
                </div>
            )
        }

        if (this.props.reklamasjoner.length){
            return(
                <div className="ms-Grid-col ms-sm12 ms-lg8 p-x-0">
                    {_.orderBy(this.props.reklamasjoner, 'id', ['desc']).map(reklamasjon=>{
                        return (
                            <ReklamasjonListItem reklamasjon={reklamasjon}/>
                        )
                    })}
                </div>

            )
        } else {
            return(
                <NoPageViewContent
                    iconName="EditNote"
                    title="Ingen Reklamasjoner"
                    descripton="Reklamasjonssaker som opprettes for leiligheten vil dukke opp her"
                />
            )
        }

    }

    renderProsjektColumn(){

        if (this.props.prosjekt){

            const {Title, Description, Image} = this.props.prosjekt;

            return(
                <div>
                    <img style={{maxWidth: '100%'}} src={Image} />
                    <h3>{Title}</h3>
                    <p>{Description}</p>
                </div>
            )
        }
    }

    render(){
        return(
            <PageViewContainer>
                <PageViewHeader
                    title="Reklamasjoner"
                    buttonTitle="Ny Sak"
                    buttonIcon="Add"
                    onButtonClicked={()=>this.props.history.push('/ny_sak')}
                    showBackButton={true}
                    onBackButtonClicked={()=>this.props.history.push('/min-side')}
                />
                <div className="ms-Grid-col ms-sm12 p-x-0">
                    <div className="ms-Grid-col ms-hiddenMdDown ms-lg4">
                        {this.renderProsjektColumn()}
                    </div>
                    {this.renderReklamasjoner()}
                </div>
            </PageViewContainer>
        )
    }
}

const mapStateToProps = (state, ownProps) =>{

    return{
        prosjekt: state.prosjekt.prosjekt,
        reklamasjoner: state.reklamasjon.reklamasjoner
    }
};

export default withRouter(connect(mapStateToProps, {getReklamasjoner, getProsjekt})(ReklamasjonListView))