import * as React from 'react';
import {Link, withRouter} from 'react-router-dom';
import PageViewContainer from "../common/PageViewContainer";
import LoginForm from "./LoginForm";
import {tokenExpired} from "../../utils/AuthUtils";


interface ILoginViewProps{
    history:any;
}

interface ILoginViewState{

}

class LoginView extends React.Component<ILoginViewProps, ILoginViewState>{

    constructor(props: Readonly<ILoginViewProps>) {
        super(props);

        // Dersom brukere enda har en gyldig innloggingssesjon blir de sendt til "Min Side".
        if (!tokenExpired()){
            this.props.history.push('/min-side');
        }
    }

    render(){
        return(
            <PageViewContainer title="Logg inn">
                <div className="ms-Grid-col ms-sm12 ms-smOffset0 ms-md10 ms-mdOffset1 ms-lg6 ms-lgOffset3">
                    <LoginForm onLoginSuccess={()=>this.props.history.push('/min-side')}/>
                </div>
            </PageViewContainer>
        )
    }
}

export default LoginView;