import * as React from 'react';
import {ActionButton, PrimaryButton} from "office-ui-fabric-react";

import './PageViewHeader.scss';

interface IPageViewHeaderProps{
    title:string;
    buttonIcon?:string;
    buttonTitle?:string;
    onButtonClicked?:()=>void;
    showBackButton?:boolean;
    onBackButtonClicked?:()=>void;
}

interface IPageViewHeaderState {

}

class PageViewHeader extends React.Component<IPageViewHeaderProps, IPageViewHeaderState>{


    constructor(props:Readonly<IPageViewHeaderProps>) {
        super(props);
    }

    renderBackButton(){

        if (this.props.showBackButton){
            return(
                <div className="page-view-header-back">
                    <ActionButton
                        iconProps={{ iconName: 'ChevronLeftMed' }}
                        onClick={()=>this.props.onBackButtonClicked()}
                    >
                        Tilbake
                    </ActionButton>
                </div>
            )
        }
    }

    renderButtonColumn(){


        if (this.props.buttonTitle){

            const {buttonTitle, buttonIcon, onButtonClicked} = this.props;
            return(
                <div className="ms-Grid-col ms-sm5 p-x-0 ms-textAlignRight">
                    <PrimaryButton iconProps={{ iconName: buttonIcon ? buttonIcon :'Add' }} onClick={()=>onButtonClicked()}>
                        {buttonTitle}
                    </PrimaryButton>
                </div>
            )
        }
    }

    render(){

        const {title, buttonTitle} = this.props;

        const titleContainerClass = `ms-Grid-col p-l-0 ${buttonTitle ? 'ms-sm7':'ms-sm12'}`;

        return(
            <div className="page-view-header ms-Grid-col ms-sm12 p-x-0 vertical-align">
                {this.renderBackButton()}
                <div className={titleContainerClass}>
                    <h2>{title}</h2>
                </div>
                {this.renderButtonColumn()}
            </div>
        )
    }
}

export default PageViewHeader;