import * as React from "react";
import {render} from "react-dom";
import {AppContainer} from "react-hot-loader";
import App from "./components/App";
import * as Favicon from 'react-favicon';

const rootEl = document.getElementById("root");

render(<div>
        <Favicon url={'https://ruta.no/wp-content/uploads/2016/05/favicon-125x125.png'} />
        <AppContainer>
            <App/>
        </AppContainer>
    </div>,
    rootEl
);

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NewApp = require("./components/App").default;

        render(
            <div>
                <Favicon url={'https://ruta.no/wp-content/uploads/2016/05/favicon-125x125.png'} />
                <AppContainer>
                    <NewApp/>
                </AppContainer>
            </div>,
            rootEl
        );
    });
}
