import * as React from 'react';
import {Spinner, SpinnerSize} from "office-ui-fabric-react";

import './SpinLoader.scss';

export interface ISpinLoaderProps{
    text:string;
}

export default class SpinLoader extends React.Component<ISpinLoaderProps>{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="spin-loader m-y-20">
                <Spinner size={SpinnerSize.large} label={this.props.text} ariaLive="assertive" />
            </div>
        )
    }
}