import * as React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, change} from 'redux-form';
import {Link, withRouter} from 'react-router-dom';
import {DefaultButton, TextField} from "office-ui-fabric-react";
import {changePassword} from "../../actions/Action_Auth";
import {toast} from "react-toastify";

interface IChangePasswordFormProps {
    error?:string;
    onPasswordChanged:()=>void;

    // Redux form
    handleSubmit:any;
    change(field:string, value:any);

    // Requests
    changePassword(password:string, newPassword:string, callback:(success:boolean)=>void);
}

interface IChangePasswordFormState{
    isAwaitingResponse:boolean;
}

class ChangePasswordForm extends React.Component<IChangePasswordFormProps, IChangePasswordFormState>{

    constructor(props: Readonly<IChangePasswordFormProps>) {
        super(props);

        this.state = {
            isAwaitingResponse: false
        }
    }

    onFormSubmit(values){

        this.setState({
            isAwaitingResponse: true
        });

        const {password, newPassword} = values;

        this.props.changePassword(values.password, values.newPassword, success=>{

            this.setState({isAwaitingResponse: false});

            if (success){
                toast("✓ Passordet har blitt endret", {type: toast.TYPE.SUCCESS});
                this.props.onPasswordChanged();
            }
        });
    };


    /****************************
     Render methods
     ****************************/

    renderInputField(field) {

        const {
            label,
            input,
            placeholder,
            required,
            isPassword,
            meta: {
                touched,
                error
            }
        } = field;

        return (
            <div className="ms-Grid-row">
                <label>{label}</label>
                <TextField
                    {...input}
                    errorMessage={touched ? error : ''}
                    placeholder={placeholder}
                    required={required ? required : false}
                    type={isPassword ? 'password': 'text'}
                />
            </div>
        );
    };

    render(){

        const {handleSubmit} = this.props;

        return(
            <form onSubmit={handleSubmit(this.onFormSubmit)} className="ms-Grid p-x-5">
                <div className="ms-Grid-col ms-sm12">
                    <Field
                        name="password"
                        label="Nåværende passord"   // Custom fields variables can be fetched in component (i.e. field.label)
                        placeholder="Skriv inn ditt gjeldende passord"
                        required={true}
                        isPassword={true}
                        component={this.renderInputField}
                    />
                    <div className="p-y-20">
                        <Field
                            name="newPassword"
                            label="Nytt passord"   // Custom fields variables can be fetched in component (i.e. field.label)
                            placeholder="Skriv inn et nytt passord"
                            required={true}
                            isPassword={true}
                            component={this.renderInputField}
                        />
                        <Field
                            name="newPasswordConfirm"
                            label="Bekreft passord"   // Custom fields variables can be fetched in component (i.e. field.label)
                            placeholder="Bekreft ditt nye passord"
                            required={true}
                            isPassword={true}
                            component={this.renderInputField}
                        />
                    </div>
                    <p style={{color: 'red', marginTop: '0'}}>{this.props.error && !this.state.isAwaitingResponse ? this.props.error : ''}</p>
                    <div className="ms-Grid-row ms-textAlignRight">
                        <DefaultButton
                            primary={true}
                            disabled={this.state.isAwaitingResponse}
                            text="Endre passord"
                            onClick={handleSubmit(this.onFormSubmit.bind(this))}
                        />
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        error: state.auth.errorPassword
    }
};

function validate(values) {

    let errors:any = {};

    if (!values.password){
        errors.password = 'Dette feltet er påkrevd.';
    }

    if (!values.newPassword){
        errors.newPassword = 'Dette feltet er påkrevd.';
    }

    if (values.newPassword && values.newPassword.length < 8){
        errors.newPassword = 'Det nye passordet må bestå av minst 8 tegn.';
    }

    if (!values.newPasswordConfirm){
        errors.newPasswordConfirm = 'Dette feltet er påkrevd.';
    }

    if (values.newPassword != values.newPasswordConfirm){
        errors.newPasswordConfirm = 'Passordene må være like.';
    }

    return errors;
}

export default reduxForm({
    form: 'ChangePasswordForm',
    validate,
    enableReinitialize: true,
    onSubmitFail: ()=>{
        // If form submission fails, we scroll to the top of the form
        window.scrollTo(0,0);
    },
})(connect(mapStateToProps, {changePassword})(ChangePasswordForm));