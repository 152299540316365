import * as React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PageViewContainer from "../common/PageViewContainer";
import {ActionButton, CompoundButton} from "office-ui-fabric-react";

import { History } from 'history';
import {lorem} from "office-ui-fabric-react/lib/utilities/exampleData";
import {getProsjekt} from "../../actions/Action_Prosjekt";
import {Prosjekt} from "../../models/Prosjekt";

import './MinSide.scss';
import RutaButton from "../common/RutaButton";

interface IMinSideProps {
    prosjekt:Prosjekt;

    history:History;

    // Requests
    getProsjekt();
}

interface IMinSideState{

}

class MinSide extends React.Component<IMinSideProps, IMinSideState>{

    constructor(props: Readonly<IMinSideProps>) {
        super(props);
    }

    componentWillMount(){

        if (!this.props.prosjekt){
            this.props.getProsjekt().then(response=>{
            });
        }
    }

    renderProsjektColumn(){

        if (this.props.prosjekt){
            console.log('props', this.props)
            const {Title, Description, Image} = this.props.prosjekt;

            return(
                <div>
                    <img style={{maxWidth: '100%'}} src={Image} />
                    <h3>{Title}</h3>
                    <p>{Description}</p>
                </div>
            )
        }
    }

    render(){
        return(
            <PageViewContainer title="Velkommen til din boligside">

                <div className="ms-Grid-col ms-sm12 ms-lg4">
                    {this.renderProsjektColumn()}
                </div>


                <div className="ms-Grid-col ms-sm12 ms-lg8">
                    <p style={{marginTop: '0px'}}>Gratulerer med ny bolig. Boligportalen er Ruta sitt kundeoppfølgingssystem for nye boliger. Her finner du finner du samlet all informasjon om din bolig. Her kan du også melde inn, og se status og tilbakemeldinger, på eventuelle reklamasjons- og garantisaker. Du vil også ha en komplett historikk over eventuelle saker i reklamasjonsperioden.</p>

                    {this.props && this.props.prosjekt && this.props.prosjekt.Title !== "Tangentoppen" && <RutaButton
                        title="Reklamasjons- og garantisaker"
                        description="Meld inn eller se status for en reklamasjonssak"
                        color="#1b4080"
                        onButtonClicked={()=>this.props.history.push('/reklamasjonsoversikt')}
                    />}

                    <RutaButton
                        title="FDV Dokumentasjon"
                        description="Last ned forvaltningsdokumenter for boligen"
                        color="#e70"
                        onButtonClicked={()=>this.props.history.push('/FDV')}
                    />

                    {/*
                    <ActionButton
                        data-automation-id="test"
                        iconProps={{ iconName: 'Unlock' }}
                        onClick={()=>this.props.history.push('/endre_passord')}
                    >
                        Endre passord
                    </ActionButton>
                    */}
                </div>
            </PageViewContainer>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        prosjekt: state.prosjekt.prosjekt
    }
};

export default withRouter(connect(mapStateToProps,{getProsjekt})(MinSide));