import axios from "axios";
import {API_CODE, BASE_API_URL} from "./Actions";


export const GET_PROSJEKT:string = 'GET_PROSJEKT';
export const RESET_PROSJEKT_STATE:string = 'RESET_PROSJEKT_STATE';

export function getProsjekt() {

    return new Promise((resolve, reject) => {
        const request = axios.get(`${BASE_API_URL}/prosjekter${API_CODE}`,{
            headers:{
                Authorization: localStorage.getItem('token')
            }
        }).then(response=>{

            resolve({
                type: GET_PROSJEKT,
                payload: response.data
            });
        }).catch(error=>{

            resolve({
                type: GET_PROSJEKT,
                payload: 'Kunne ikke hente ut prosjekt'
            });
        });
    });
}

export function resetProsjektState() {
    return{
        type: RESET_PROSJEKT_STATE,
        payload: null
    }
}