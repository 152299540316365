import * as React from "react";
import {Fabric} from "office-ui-fabric-react";
import ScrollUpButton from "react-scroll-up-button";
import {withRouter, BrowserRouter, Route, Switch, browserHistory} from 'react-router-dom';
import Routes from "./routes/Routes";

import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import reducers from "./../reducers/Reducers";
import { Provider } from 'react-redux';
import {ToastContainer, toast, Slide} from 'react-toastify';

import { initializeIcons } from '@uifabric/icons';
import "../../node_modules/office-ui-fabric-core/dist/sass/Fabric.scss";
import "./App.scss";
import 'react-toastify/dist/ReactToastify.css';

import Navbar from "./navbar/Navbar";

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);
initializeIcons(undefined, { disableWarnings: true });

export interface AppProps {
}

export default class App extends React.Component<AppProps, undefined> {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <Provider store={createStoreWithMiddleware(reducers)}>
                <Fabric>
                    <BrowserRouter>
                        <div>
                            <Navbar/>
                            <Routes/>
                            {/*
                            <Footer/>
                            */}
                        </div>
                    </BrowserRouter>
                    <ToastContainer autoClose={5000} transition={Slide} />
                    <ScrollUpButton
                        StopPosition={0}
                        TransitionBtnPosition={150}
                        EasingType='easeOutCubic'
                        AnimationDuration={500}
                        ContainerClassName='ScrollUpButton__Container'
                        TransitionClassName='ScrollUpButton__Toggled'
                        style={{borderRadius:'50%'}}
                        ToggledStyle={{}}
                    />
                </Fabric>
            </Provider>
        );
    }
}
